export default {
  projectTitle: "中国艺术银行",
  // 路由
  route: {
    home: "首页",
    learnMore: "详情",
    artist: "艺术家",
    artisticActivity: "艺术活动",
    artwork: "艺术品",
    financialService: "金融服务",
    hotelClub: "酒店俱乐部",
    supportSystem: "支撑体系",
    tradingSystem: "交易体系",
    legalSystem: "法务体系",
    trainSystem: "培训体系",
    serviceSystem: "服务体系",
    marketingSystem: "营销体系",
    financialSystem: "金融体系",
    supplyChainSystem: "供应链体系",
    sortingSystem: "清分体系",
    moisteningSystem: "分润体系",
    operationSystem: "营运体系",
    integratedSystem: "整合体系",
    backdoorSystem: "借壳体系",
    incentiveSystem: "激励体系",

    memberCenter: "会员中心",
    contactUs: "联系我们",
    newsCenter: "新闻中心",
    beijing: "北京分行",
    shanghai: "上海分行",
    guangzhou: "广州分行",
    shenzhen: "深圳分行",
    hongkong: "香港分行",
    macau: "澳门总部",
    newyork: "纽约分行",
    sanFrancisco: "旧金山分行",
    singapore: "新加坡分行",
    tokyo: "东京分行",
    create: "艺术创作",
    behavior: "艺术行为",
    collect: "艺术收藏",
    oilPainting: "油画水粉",
    ly1: "刘宇一",
    inkCalligraphy: "水墨书法",
    sketchPrint: "素描版画",
    holding: "馆藏作品",
    music: "音乐",
    popMusic: "流行音乐",
    jazzMusic: "爵士乐",
    classicalMusic: "古典乐",
    dance: "舞蹈",
    classicalDance: "古典舞",
    folkDance: "民族舞",
    modernDance: "现代舞",
    drama: "戏剧",
    opera: "歌剧",
    danceDrama: "舞剧",
    puppetShow: "木偶戏",
    shadowPlay: "皮影戏",
    quyi: "曲艺",
    brand: "牌子曲类",
    balladCi: "弹词类",
    drumsMelodies: "鼓曲类",
    luteCalligraphy: "琴书类",
    dogen: "道琴类",
    personage: "个人收藏",
    folk: "民间收藏",
    theme: "主题收藏",
    artGallery: "艺术馆收藏",
    insurance: "金融保险",
    pingan: "平安保险",
    taiping: "太平保险",
    lease: "金融租赁",
    factor: "金融保理",
    service: "金融服务",
    artPackage: "艺术资产包",
    social: "社会活动", // 社会活动
    exhibitionTour: "参展巡展",
    office: "任职任教",
    majorTheme: "重大题材",
    acceptStudents: "收徒",
    exhibition: "办展",
    gift: "馈赠",
    associationPen: "笔会",
    internationalExhibitionTour: "国际巡展",
    beltRoad: "一带一路",
    stateAuction: "国家拍卖",
    importantCollection: "重要收藏",
    internationalLecture: "国际讲学",
    autonomous: "自主活动", // 自主活动
    internationalExchange: "国际交流", // 国际交流
    collectible: "收藏品", //
    sort: "分类",
    all: "全部",
    calligraphyInscriptions: "书法题字",
    chinese: "水墨国画",
    figureOil: "人物油画",
    stoneRhyme: "石韵画",
    world: "世界系列",
    author: "作者",
    dimension: "尺寸",
    price: "价格",
    appraisalCenter: "鉴定中心",
    wenChiao: "文交系统",
    south: "南方文交所",
    shenzhenWjs: "深圳文交所",
    identificationEvaluation: "鉴定与评估委员会",
    collectionEvaluation: "收藏评估委员会",
    groggery: "艺术银行酒店", //艺术银行酒店
    club: "艺术银行俱乐部", //艺术银行俱乐部
    arts: "艺术类",
    trainingSystem: "艺术金融培训体系",
    professionalCommittee: "艺术金融专业委员会",
    hotelCategory: "酒店类",
    financeType: "金融类",
    museum: "全国画廊/博物馆",
    membershipService: "艺术金融会员服务",
    intro: "集团简介", // 集团简介
    news: "集团新闻", // 集团新闻
    newsInfo: "新闻详情", // 新闻详情
    announcement: "公示公告",
    announcementInfo: "公示公告详情",
    industryDynamics: "行业动态",
    industryDynamicsInfo: "行业动态详情",
    videoData: "视频资料",
    documentData: "文件资料",
    mediaReport: "媒体报道", //媒體报道
    mediaReportInfo: "媒体报道详情", //媒體报道详情
    corporateCulture: "企业文化", // 企业文化
    organizationalStructure: "组织架构", // 组织架构
    aptitude: "荣誉资质" // 荣誉资质
  },
  // 首页
  home: {
    // 业务板块
    businessSegment: {
      title: "业务·板块",
      pledge: "艺术品质押",
      pawn: "艺术品典当",
      trust: "艺术品信托",
      insurance: "艺术品保险",
      consult: "艺术品咨询",
      lease: "艺术品租赁",
      securitization: "艺术证券化",
      equityTransaction: "艺术品产权交易" //艺术品产权交易
    },
    // 精选艺术
    art: {
      title: "精选·艺术"
    },
    // 艺术活动
    artActivity: {
      title: "艺术·活动",
      roadRejuvenation: "复兴之路",
      socialActivity: "社会活动",
      autonomousActivity: "自主活动",
      internationalExchange: "国际交流"
    },
    // 新闻中心
    newsCenter: {
      title: "新闻中心",
      expertWork: "专家工作",
      projectApplication: "项目申报",
      projectSupervision: "项目监管",
      projectSupervisionArt: "艺术部分",
      projectSupervisionExpenditure: "经费部分",
      viewMore: "查看更多"
    },
    // 页尾
    foot: {
      //关于我们
      aboutUs: {
        title: "关于我们",
        corporateCulture: "企业文化", // 企业文化
        organizationalStructure: "组织架构", // 组织架构
        qualificationHonor: "资质荣誉", // 资质荣誉
        newsInformation: "新闻资讯", // 新闻资讯
        websiteStatement: "网站申明", // 网站申明
        siteMap: "站点地图" // 站点地图
      },
      //集团业务
      groupBusiness: {
        title: "集团业务",
        artPledge: "艺术品典押", // 艺术品典押
        artPawning: "艺术品典当", // 艺术品典当
        artTrust: "艺术品信托", // 艺术品信托
        artInsurance: "艺术品保险", // 艺术品保险
        artSecuritization: "艺术证券化", // 艺术证券化
        equityTransaction: "艺术产权交易" // 艺术产权交易
      },
      //投资者关系
      investorRelations: {
        title: "投资者关系",
        informationDisclosure: "信息披露", // 信息披露
        performanceReport: "业绩报告", // 业绩报告
        socialResponsibility: "社会责任", // 社会责任
        OverseasBranch: "境外分行", // 境外分行
        clause: "条款细则", // 条款细则
        joinUs: "加入我们" // 加入我们
      },
      //联系我们
      contactUs: {
        title: "联系我们",
        serviceHotline: "服务热线", // 服务热线
        suggestion: "投诉建议", // 投诉建议
        workingHours: "工作时间", // 工作时间
        postalCode: "邮政编码", // 邮政编码
        companyAddress: "公司地址", // 公司地址
        companyAddressDes: "中国澳门大堂区新口岸冼星海路" // 公司详细地址
      }
    }
  },

  // 金融服务
  service: {},
  // 联系我们
  contact: {
    address: "地址",
    telephone: "电话",
    mail: "邮件",
    hotWire: "热线",
    beijing: "北京市丰台区汉威国际广场三区2号楼8M",
    shanghai: "中国上海市浦东新区银城路116号",
    guangzhou: "广东省广州市番禺区兴南大道32号1-2层",
    shenzhen: "中国广东省深圳市福田区金田路2030号卓越世纪中心49楼",
    hongkong: "中国香港湾仔骆克道385-387号裕安商业大厦16楼",
    macau: "中国澳门大堂区新口岸冼星海大马路澳门文化中心",
    newyork: "美国纽约林肯中心广场NY 10023",
    sanFrancisco: "美国加利福尼亚州 旧金山，林肯公园 100号 34大街94121",
    singapore: "新加坡湾景道10号018956艺术科学博物馆",
    tokyo: "日本东京都台东区上野公园12-8号",
    suggestion1: "如果您有好的意见或建议；",
    suggestion2: "如果您对我们的工作尚有不满意之处；",
    suggestion3: "如果您想对中国艺术银行有更进一步的了解……",
    suggestion4: "请与我们联系，我们渴望与您交流，在交流中成长。"
  },
  common: {
    noContent: "暂无内容" // 暂无内容
  }
};
