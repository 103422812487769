<script>
import { LANG } from "@/utils/enum";

export default {
  name: "LangSelect",
  data() {
    return {
      routerList: [], // 路由设置
      options: [
        {
          value: "zh",
          label: "简体"
        },
        {
          value: "zhTw",
          label: "繁體"
        },
        {
          value: "en",
          label: "EN"
        }
      ],
      // langDef: this.$store.state.settings.lang
      langDef: localStorage.getItem(LANG)
    };
  },
  methods: {
    langChange(e) {
      // this.$nextTick无法修改方式三
      this.$nextTick(() => {
        localStorage.setItem(LANG, e);
        this.$i18n.locale = e;
        this.langDef = e;
        this.$store.dispatch("settings/changeSetting", {
          key: "lang",
          value: e
        });
      });
    }
  }
};
</script>

<template>
  <el-select
    @change="langChange"
    placeholder="请选择语言"
    class="lang_warp"
    v-model="langDef"
    :popper-append-to-body="false"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<style scoped lang="scss">
@import "../../../styles/variables.scss";

.lang_warp {
  width: 96px;
  /deep/ {
    .el-input {
      width: 104px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 20px;
    }
    .is-focus,.el-input:hover{
      background-color: $theme-orange;
    }
    .el-input--suffix .el-input__inner {
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      border: 0px;
      color: #ffffff !important;
      text-align: center;
      height: 32px;
      font-size: 0.8rem;
    }
    .el-input__icon {
      line-height: 32px;
    }
    .selected {
      color: $theme-red !important;
    }
    .el-select__caret {
      color: #ffffff !important;
    }
  }
}
</style>
