<template>
  <section class="app-main">
    <transition name="fade" mode="out-in">
      <!-- <router-view :key="key"></router-view> -->
      <router-view :key="$route.fullPath" />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/variables.scss";

.app-main {
  /*50 = navbar  */
  //min-height: calc(100vh - 400px);
  min-width: $min-width;
  width: 100%;
  position: relative;
  //overflow: hidden;
  box-sizing: content-box;
  padding-top: 100px;
}

.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
// .el-popup-parent--hidden {
//   .fixed-header {
//     padding-right: 15px;
//   }
// }
</style>
