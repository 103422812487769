<template>
  <div>
    <div class="layout_warp">
      <router-link key="collapse" class="sidebar-logo-link" to="/home">
        <el-image
          style="padding: 16px 24px;"
          :src="logo"
          fit="scale-down"
          lazy
        />
      </router-link>
      <el-menu
        :default-active="selectedKeys"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#FFFFFF"
        text-color="#000000"
        active-text-color="rgb(255 78 0)"
        @select="handleSelect"
      >
        <el-menu-item
          :index="item.key"
          v-for="item in routerList"
          v-show="!item.hidden"
          :key="item.key"
        >
          <router-link key="collapse" :to="item.path">
            {{ generateTitle(item.key) }}
          </router-link>
        </el-menu-item>
      </el-menu>
      <div class="lang_warp">
        <LangSelect />
      </div>
    </div>
    <app-main />
    <Foot />
  </div>
</template>

<script>
import { AppMain, Foot, LangSelect } from "./components";
import { constantRouterMap } from "@/router";
import { LANG } from "@/utils/enum";
import { generateTitle } from "@/i18n";

export default {
  name: "Layout",
  components: {
    AppMain,
    Foot,
    LangSelect
  },
  data() {
    return {
      selectedKeys: "home",
      title: this.$store.state.settings.title,
      logo: this.$store.state.settings.logo,
      routerList: [], // 路由设置
      options: [
        {
          value: "zh",
          label: "中文"
        },
        {
          value: "zhTw",
          label: "中文-繁体"
        },
        {
          value: "en",
          label: "English"
        }
      ],
      // langDef: this.$store.state.settings.lang
      langDef: localStorage.getItem(LANG)
    };
  },
  watch: {
    myNum(val) {
      this.selectedKeys = val;
    }
  },
  computed: {
    myNum() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedKeys = this.$store.state.user.selectedKeys;
      return this.$store.state.user.selectedKeys;
    }
  },
  mounted() {},
  created() {
    if (constantRouterMap) {
      this.routerList = constantRouterMap[0].children;
    }
    const currentPath = this.$route.path;
    if (currentPath) {
      this.selectedKeys = currentPath.replace("/", "");
    }
  },
  methods: {
    generateTitle,
    handleSelect(key, keyPath) {
      this.$store.commit("SET_SELECTEDKEYS", key);
      console.log("keyPath", keyPath);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
@import "src/styles/variables.scss";

.layout_warp {
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  //min-width: $min-width;
  height: 100px;
  z-index: 2002;
  //overflow-x: auto;
  .sidebar-logo-link {
    display: flex;
  }
  .sidebar-title {
    padding: 10px 10px;
    color: #fff;
    font-size: 24px;
  }
}

.demonstration {
  color: var(--el-text-color-secondary);
}
#components-layout-demo-fixed .logo {
  width: 120px;
  height: 31px;

  margin: 16px 24px 16px 0;
  float: left;
}
.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}
/*最大屏幕分辨率为1280px*/
@media screen and (max-width: 1279px) {
  .lang_warp {
    margin-top: 16px;
  }
  /deep/ {
    .el-menu--horizontal > .el-menu-item,
    .el-menu--horizontal > .el-menu-item.is-active {
      height: 100px;
      line-height: 100px;
      font-size: 1rem;
      padding: 0 10px;
    }
  }
}
/*最小屏幕分辨率为1280px且最大为1440px*/
@media (min-width: 1280px) {
  .lang_warp {
    margin-top: 16px;
    margin-right: 16px;
  }
  /deep/ {
    .el-menu--horizontal > .el-menu-item,
    .el-menu--horizontal > .el-menu-item.is-active {
      height: 100px;
      line-height: 100px;
      font-size: 1.2rem;
    }
  }
}
/*最小屏幕分辨率为1440px且最大为1680px*/
@media (min-width: 1440px) and (max-width: 1680px) {
}
/*最小屏幕分辨率为1920px*/
@media screen and (min-width: 1920px) {
}
/*最小屏幕分辨率为2560px*/
@media screen and (min-width: 2560px) {
}
</style>
