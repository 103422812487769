import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        key: "home",
        name: "首页"
      },
      {
        path: "/home/learnMore",
        component: () => import("@/views/home/LearnMore.vue"),
        hidden: true,
        name: "learnMore",
        key: "learnMore"
      },
      {
        path: "/artist",
        component: () => import("@/views/artist/index.vue"),
        key: "artist",
        name: "艺术家"
      },
      {
        path: "/artisticActivity",
        component: () => import("@/views/artisticActivity/index.vue"),
        key: "artisticActivity",
        name: "艺术活动"
      },
      {
        path: "/artwork",
        component: () => import("@/views/artwork/index.vue"),
        key: "artwork",
        name: "艺术品"
      },
      {
        path: "/financialService",
        component: () => import("@/views/financialService/index.vue"),
        key: "financialService",
        name: "金融服务"
      },
      {
        path: "/hotelClub",
        component: () => import("@/views/hotelClub/index.vue"),
        key: "hotelClub",
        name: "酒店俱乐部"
      },
      {
        path: "/supportSystem",
        component: () => import("@/views/supportSystem/index.vue"),
        key: "supportSystem",
        name: "支撑体系"
      },
      // {
      //   path: "/memberCenter",
      //   component: () => import("@/views/memberCenter/index.vue"),
      //   key: "memberCenter",
      //   name: "会员中心"
      // },
      {
        path: "/newsCenter",
        component: () => import("@/views/NewsCenter/index.vue"),
        key: "newsCenter",
        name: "新闻中心"
      },
      {
        path: "/contactUs",
        component: () => import("@/views/contactUs/index.vue"),
        key: "contactUs",
        name: "联系我们"
      },
      {
        path: "/",
        redirect: "/home",
        hidden: true
      },
      {
        path: "/401",
        component: () => import("@/views/error/401.vue"),
        hidden: true,
        name: "401",
        key: "401"
      },
      {
        path: "/404",
        component: () => import("@/views/error/404.vue"),
        hidden: true,
        name: "404",
        key: "404"
      },
      {
        path: "*",
        redirect: "/404",
        hidden: true
      }
    ]
  }
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: "history",
  routes: constantRouterMap
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [];
