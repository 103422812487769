import { getToken, removeToken } from "@/utils/auth";

const user = {
  state: {
    token: getToken(),
    username: "",
    avatar: "",
    roles: [],
    permissions: [],
    selectedKeys: "home"
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_SELECTEDKEYS: (state, selectedKeys) => {
      state.selectedKeys = selectedKeys;
    }
  },

  actions: {

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit("SET_TOKEN", "");
        commit("SET_PERMISSIONS", []);
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
