export const LANG = "artBankVhina-lang";

export const LANG_OPTION = [
  {
    value: "zh",
    label: "中文"
  },
  {
    value: "zhTw",
    label: "中文-繁体"
  },
  {
    value: "en",
    label: "English"
  }
];

/**
 * 新闻类型 (1-集团新闻，2-公示公告，3-行业动态，4-媒体报道，5-视频资料)
 * @type {[{label: string, value: string},{label: string, value: string},{label: string, value: string}]}
 */
export const NEWS_CENTER_TYPE = {
  news: 1, //集团新闻
  announcement: 2, //公示公告
  industryDynamics: 3, //行业动态
  mediaReport: 4, //媒体报道
  videoData: 5, //视频资料
  documentData: 6 //文件资料
};
