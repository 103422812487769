import Vue from "vue";
// import VueAMap from 'vue-amap'; // 高德地图
import "normalize.css/normalize.css"; // A modern alternative to CSS resets
// import Antd from 'ant-design-vue';

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css

import "ant-design-vue/dist/reset.css";
import "./assets/font/font.scss";
import "./styles/variables.scss";

import App from "./App";
import "ant-design-vue/dist/reset.css";

import router from "./router";
import store from "./store";
import moment from "moment";
import i18n from "./i18n";
import "@/permission";

// import '@/icons' // icon
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
});

// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: process.env.VUE_APP_AMAP_JSAPI_KEY,
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation','AMap.Marker'],
//   uiVersion: '1.0', // ui库版本，不配置不加载,
//   v: '1.4.10' // 默认高德 sdk 版本为 1.4.4
// });

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

// 自定义上传组件
import UploadOne from "@/components/UploadOne";

Vue.component("upload-one", UploadOne);
// 注册一个全局自定义指令 `v-scroll`，绑定滚动事件
// Vue.directive("scroll", {
//   inserted: function(el, binding) {
//     let f = function(evt) {
//       if (binding.value(evt, el)) {
//         window.removeEventListener("scroll", f);
//       }
//     };
//     window.addEventListener("scroll", f);
//   }
// });

new Vue({
  el: "#app",
  i18n,
  router,
  store,
  render: h => h(App)
});
