import router from "./router";
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式

router.beforeEach((to, from, next) => {
  if (router.currentRoute.path === to.path) {
    // 当前路由就是即将跳转的路由，不再执行跳转
    console.log("Already at the target route, ignoring navigation");
  }
  next(); // 继续执行路由跳转
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
